<template>
  <div class="w-full bg-white p-2 overflow-auto">
    <b-table
      striped
      class="my-2 text-sm text-center"
      id="userPackageTable"
      small
      hover
      :fields="fields"
      :items="packages"
      responsive="sm"
      head-variant="dark"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #top-row  v-if="packages.length===0">
        <td colspan="3">No hay paquetes disponibles</td>
      </template>
      <template #cell(state)="data">
        {{data.value}}
      </template>
      <template #cell(package)="data">
        <b>{{data.value}}</b>
      </template>
    </b-table>
    <b-pagination
      v-if="packages.length>0"
      class="mt-4"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>
<script>

export default {
  name: 'UserPackageTable',
  props: {
    packages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    rows() {
      return this.packages.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: 'description', label: 'Descripción' },
        { key: 'status_name', label: 'Estado' },
        { key: 'length', label: 'Paquetes' },
      ],
      items: [
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
        {
          state: 'En Espera', package: '11',
        },
      ],
    };
  },
};
</script>

<style scoped>
  .text-information {
    color: var(--primary-blue) !important;
  }

</style>
