<template>
  <div class="flex flex-col w-full">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <section class="w-full bg-white p-2 my-8">
      <div class="w-full my-2 flex justify-between">
        <h3 class="text-start mt-1 text-xl">Usuarios</h3>
        <div class="flex p-0.5 bg-blue-700 rounded self-center">
          <icon
            @click.native="() =>
            $router.push({ name: 'Editar Informacion', params: { id: user.id } })"
            class="text-white" name="EditIcon" />
        </div>
      </div>
      <div class="flex grid grid-cols-2 border-t pt-2 border-gray-300">
        <div class="flex mt-2 w-full grid grid-cols-1">
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Tipo de cuenta:</label>
            <p class="w-1/2 capitalize">{{user.user_information.account_type != null ?
            user.user_information.account_type
            : 'N/A'}}</p>
          </div>
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Nombre:</label>
            <p class="w-1/2">
              {{user.user_information.first_name}}
              {{user.user_information.last_name}}
            </p>
          </div>
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Nombre de la Empresa:</label>
            <p class="w-1/2">
              {{user.user_information.company != null ? user.user_information.company : 'N/A'}}
            </p>
          </div>
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Correo Electrónico:</label>
            <p class="w-1/2">{{user.email}}</p>
          </div>
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Código:</label>
            <p class="w-1/2">GV-{{user.id}}</p>
          </div>
          <div class="flex">
            <label class="flex w-1/2 justify-end mr-4">Identificación:</label>
            <p class="w-1/2">
              {{user.user_information.identification != null ?
              user.user_information.identification
              : 'N/A'}}</p>
          </div>
          <div class="flex w-4/5 justify-between">
            <label class="mr-2">Estado:</label>
            <p
              class="px-2 py-1 text-white  self-center rounded text-xs"
              :class="user.enabled ? 'bg-green-500' : 'bg-red-500'">
              {{user.enabled ? 'Activo' : 'Inactivo'}}
            </p>
            <label class="mr-2">Cuenta Completa:</label>
            <p class="px-2 py-1 text-white self-center rounded text-xs"
              :class="user.account_complete ? 'bg-green-500' : 'bg-red-500'">
              {{user.account_complete ? 'Si' : 'No'}}
            </p>
            <label class="mr-2">Verificado:</label>
            <p class="px-2 py-1 text-white self-center rounded text-xs"
              :class="user.verified ? 'bg-green-500' : 'bg-red-500'">
              {{user.verified ? 'Si' : 'No'}}
            </p>
          </div>
        </div>
        <div class="flex w-full m-auto grid border border-gray-200 grid-cols-1">
          <div class="flex flex-col w-full">
            <h3 class="w-full bg-gray-200 text-gray-400 p-2 text-xs">Dirección de entrega</h3>
            <div class="px-3">
              <p class="text-xs font-bold my-2">{{user.name}} {{user.code}}</p>
              <p
                class="text-xs font-normal text-gray-800 my-2">
                  Entregar en sucursal: {{user.store}}
              </p>
              <p class="text-xs my-2">{{user.primaryNumber}} / {{user.secondaryNumber}}</p>
            </div>
          </div>
          <div class="flex w-full border-t border-gray-200 p-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d31486.26025042398!2d-84.148224!3d9.4404608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2scr!4v1635461275332!5m2!1sen!2scr"
              width="100%"
              height="200"
              style="border:0;"
              allowfullscreen=""
              loading="lazy">
            </iframe>
          </div>
        </div>
      </div>
        <div class="w-full  flex justify-between">
        <h3 class="text-start mt-4 text-xl">Paquetes</h3>
      </div>
      <div class="flex w-full">
        <user-package-table :packages="user.packages" />
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import UserPackageTable from '@/components/UserPackageTable.vue';
import Icon from '@/components/Icon.vue';
import request from '@/api/request-services';
import UserMixin from '@/mixins/UserMixin';

export default {
  name: 'UserInfo',
  mixins: [UserMixin],
  components: { BreadCrumbs, UserPackageTable, Icon },
  data() {
    return {
      routes: [
        'Inicio',
        'Informacion de Usuario',
      ],
      // user: {
      //   name: '',
      //   accountType: 'Personal',
      //   company: 'Unknown',
      //   email: '',
      //   code: 2343,
      //   idNumber: '1-1638-0986',
      //   state: 'Activo',
      //   primaryNumber: '88888888',
      //   secondaryNumber: '25432343',
      //   accountCompleted: 'Si',
      //   verified: 'Si',
      //   store: 'Cartago',
      // },
      // userData: {
      //   id: '',
      //   name: '',
      //   account_type: '',
      //   email: '',
      //   code: 2343,
      //   idNumber: '1-1638-0986',
      //   state: 'Activo',
      //   primaryNumber: '88888888',
      //   secondaryNumber: '25432343',
      //   account_complete: false,
      //   verified: false,
      //   store: '',
      //   enabled: false,
      //   user_information: {
      //     first_name: '',
      //     last_name: '',
      //     company: '',
      //     identification: '',
      //     identification_type: [],
      //   },
      // },
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    async handleUserInfo() {
      const response = await request.requestGET(`user/${this.$route.params.id}`, this.getToken);
      if (response.status === 200) {
        this.setCat(response.data);
      }
    },
  },
  mounted() {
    this.handleUserInfo();
  },
};
</script>
